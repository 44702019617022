<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <products-modal
      ref="productsModal"
      :selectedData="selectedData"
    />
    <MyDataTable
      title="فایل های فروشنده های موجود"
      subtitle="مدیریت فایل های فروشنده"
      addtitle="ایجاد فایل های فروشنده جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #middleButtonTag="{ row }">
        <v-btn
          class="mx-2"
          dark
          small
          color="primary"
          @click="onDetailsClick(row)"
        >
          <v-icon small dark>mdi-apps</v-icon>
          مشاهده
        </v-btn>
      </template>
      <template #contentTag="{ row }">
        <td>
          {{
            row.user && row.user.first_name && row.user.last_name
              ? row.user.first_name + " " + row.user.last_name
              : "-"
          }}
        </td>
        <td>
          {{ row.product && row.product.name ? row.product.name : "-" }}
        </td>
        <td>{{ row.price || "-" }}</td>
        <td>{{ row.commission }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";
import ProductsModal from "../../../../components/Panel/ProductsModal.vue";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
    ProductsModal,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/vendors/index", {
        params: {
          page: this.$route.query?.page || 1,
          with: ["user", "product"],
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        path: "/sellersmanager/edit/" + item.id,
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      // if (item.product) window.open("products/" + item.product?.slug);
      this.selectedData = item.product;
      this.$refs.productsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/vendors/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/vendors/index",
      pathParams: {
        with: ["user", "product"],
      },
      headers: [
        {
          text: "کاربر",
          align: "start",
          value: "user_id",
        },
        { text: "فایل", value: "product_id" },
        { text: "قیمت", value: "price" },
        { text: "کمیسیون", value: "commission" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
