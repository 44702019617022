<template>
  <v-dialog v-model="dialog">
    <v-card :loading="loading">
      <v-card-title>
        جزئیات فایل "{{ selectedData.name ? selectedData.name : "" }}"
        <v-spacer />

        <v-btn
          outlined
          color="success"
          class="ml-2"
          @click="open('/products/' + selectedData.slug)"
        >
          مشاهده صفحه
        </v-btn>

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row v-if="selectedData.demo">
          <v-col class="d-flex child-flex" cols="12">
            <v-img :src="selectedData.demo.url" class="dialog-img" contain/>
            <!-- <video-player
              v-if="dialog"
              :sources="[
                {
                  src: selectedData.demo.url,
                  type: 'video/mp4',
                },
              ]"
              :options="{ autoplay: true }"
            /> -->
          </v-col>
        </v-row>
        <v-row v-if="selectedData.media">
          <v-col class="d-flex child-flex" cols="12" sm="6" md="4" lg="2">
            <v-img
              :src="selectedData.media.url"
              class="grey lighten-2"
              height="200px"
              contain
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="selectedData.images_media">
            <v-col
              class="d-flex child-flex"
              cols="12"
              sm="6"
              md="4"
              lg="2"
              v-for="(img, i) in selectedData.images_media"
              :key="i"
            >
              <v-img
                :src="img.url"
                class="grey lighten-2"
                height="200px"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <small>نام فایل</small>
            <h3 class="black--text">{{ selectedData.name }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.viewed"
          >
            <small>تعداد بازدید</small>
            <h3 class="black--text">{{ selectedData.viewed }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.is_featured"
          >
            <small>فایل ویژه</small>
            <h3 class="black--text">{{ selectedData.is_featured }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.status"
          >
            <small>وضعیت</small>
            <h3 class="black--text">{{ selectedData.status }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.author"
          >
            <small>قرار دهنده</small>
            <h3 class="black--text">
              {{
                selectedData.author &&
                selectedData.author.first_name &&
                selectedData.author.last_name
                  ? selectedData.author.first_name +
                    " " +
                    selectedData.author.last_name
                  : ""
              }}
            </h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.price"
          >
            <small>قیمت</small>
            <h3 class="black--text">
              {{ Intl.NumberFormat().format(selectedData.price) }}
            </h3>
          </v-col>
        </v-row>
        <small class="mt-5">توضیحات کوتاه</small>
        <h4 class="mt-4">{{ selectedData.short_desc }}</h4>
        <small class="mt-5">توضیحات</small>
        <h4 class="mt-4 fix-html-images" v-html="selectedData.long_desc"></h4>
        <v-row align="center" justify="end" class="mt-8 mb-3" v-if="isAdmin">
          <v-btn
            class="ma-2"
            dark
            small
            color="red"
            :loading="loading"
            @click="setStatus(2)"
          >
            <v-icon small dark>mdi-close</v-icon>
            <!-- {{ row.status == 2 ? "آگهی رد شده" : "رد آگهی" }} -->
            رد
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            small
            color="success"
            :loading="loading"
            @click="setStatus(1)"
          >
            <v-icon small dark>mdi-check-bold</v-icon>

            <!-- {{ row.status == 1 ? "آگهی تایید شده" : "تایید آگهی" }} -->
            تایید
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import VideoPlayer from "../VideoPlayer.vue";
export default {
  components: { VideoPlayer },
  data() {
    return {
      dialog: false,
      VideoPlayerdialog: false,
      loading: false,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    open(url) {
      window.open(url, "_blank");
    },
    setStatus(status) {
      this.loading = true;
      MyAxios.post("/products/" + this.selectedData.slug + "/update", {
        status,
      })
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.$root.$emit("toast", {
            text: "وضعیت فایل با موفقیت تغییر کرد.",
            type: "success",
          });
          this.$emit("GetList");
          // this.GetList();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.dialog-img {
  height: 30vh;
}
</style>
